import React, { Suspense } from 'react';
import "./index.less";
import { Divider, Drawer, Layout, Space, theme as antTheme } from 'antd';
import { Outlet } from 'react-router-dom';

import { useLayoutConfig } from '~/stores/layoutConfig';
import MainHeader from './MainHeader';
import MainMenu from './MainMenu';
import MainTabs from './MainTabs';
import { useWindowSize } from '~/hooks/useWindowSize';

const { Sider, Content } = Layout;



const MainLayout = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 900;
  const token = antTheme.useToken();
  const { setCollapsed, collapsed, menus, openKeys, selectedKey } = useLayoutConfig();

  const toggle = () => {
    setCollapsed(!collapsed)
  };

  return (
    <Layout className="layout-page">
      <MainHeader collapsed={collapsed} toggle={toggle} />
      <Layout>
        {!isMobile ? (
          <Sider
            className="layout-page-sider"
            trigger={null}
            collapsible
            style={{ backgroundColor: token.token.colorBgContainer }}
            collapsedWidth={isMobile ? 0 : 80}
            collapsed={collapsed}
            breakpoint="md"
          >
            <MainMenu
              menus={menus}
              openKeys={openKeys}
              selectedKey={selectedKey}
            />
          </Sider>
        ) : (
          <Drawer
            width="200"
            placement="left"
            bodyStyle={{ padding: "10px 0px 0px", height: '100%', background: "#141414" }}
            closable={false}
            onClose={toggle}
            open={!collapsed}
            style={{ margin: "50px 0px 0px", background: "#141414" }}
          >
            <MainMenu
              menus={menus}
              openKeys={openKeys}
              selectedKey={selectedKey}
            />
          </Drawer>
        )}
        <Content className="layout-page-content" >
          <MainTabs />
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  )
}
export default MainLayout;
