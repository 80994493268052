import type { FC } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { LocaleFormatter } from '~/locales';
import { useLayoutConfig } from '~/stores/layoutConfig';


const TabsAction: FC = () => {
  const { selectedKey, removeTab, removeAllTab, removeOtherTab } = useLayoutConfig();


  return (
    <Dropdown
      menu={{
        items: [
          {
            key: '0',
            onClick: () => removeOtherTab(),
            label: <LocaleFormatter id="tagsView.operation.closeOther" />,
          },
          {
            key: '1',
            onClick: () => removeTab(selectedKey),
            label: <LocaleFormatter id="tagsView.operation.closeCurrent" />,
          },

          {
            key: '2',
            onClick: () => removeAllTab(),
            label: <LocaleFormatter id="tagsView.operation.closeAll" />,
          },
          // {
          //   key: '3',
          //   type: 'divider',
          // },
          // {
          //   key: '4',
          //   // onClick: () => dispatch(removeOtherTag()),
          //   label: <LocaleFormatter id="tagsView.operation.dashboard" />,
          // },
        ],
      }}
    >
      <span id="pageTabs-actions">
        <SettingOutlined className="tagsView-extra" />
      </span>
    </Dropdown>
  );
};

export default TabsAction;
