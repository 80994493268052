


import { FC, useState } from 'react';
import screenfull from "screenfull";
import { DownOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Layout, theme as antTheme, Tooltip, Button } from 'antd';
import { createElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Avator from '~/assets/header/avator.jpeg';
import { ReactComponent as EnUsSvg } from '~/assets/header/en_US.svg';
import { ReactComponent as LanguageSvg } from '~/assets/header/language.svg';
import { ReactComponent as MoonSvg } from '~/assets/header/moon.svg';
import { ReactComponent as SunSvg } from '~/assets/header/sun.svg';
import { ReactComponent as ZhCnSvg } from '~/assets/header/zh_CN.svg';
import AntdSvg from '~/assets/logo/antd.svg';
import WebLogo from '~/assets/logo/logo_no_title.png';
import ReactSvg from '~/assets/logo/react.svg';
import ALogo from '~/assets/logo/A.png';
import BLogo from '~/assets/logo/B.png';
import CLogo from '~/assets/logo/C.png';
import { LocaleFormatter, useLocale } from '~/locales';
import { useThemeStore } from '~/stores/themeStore';
import { useLayoutConfig } from '~/stores/layoutConfig';
import FullScreen from '~/components/FullScreen';
import { configEnv } from '~/@config';
import ChainSelector from '~/layouts/containers/ChainSelector';
import ConnectWalletButton from '~/layouts/containers/ConnectWalletButton';
import AccountWallet from '~/layouts/containers/AccountWallet';
import { useWindowSize } from '~/hooks/useWindowSize';


const { Header } = Layout;


type IMainHeaderProps = {
  collapsed: boolean;
  toggle: () => void;
}

type Action = 'userInfo' | 'userSetting' | 'logout';



const MainHeader: FC<IMainHeaderProps> = ({ collapsed, toggle }: IMainHeaderProps) => {

  const token = antTheme.useToken();
  const { width } = useWindowSize();
  const { mode, themeStyle, changeTheme } = useThemeStore();
  const navigate = useNavigate();
  const { formatMessage } = useLocale();

  const { device } = useLayoutConfig();
  const onChangeTheme = () => {
    const newTheme = themeStyle === 'dark' ? 'light' : 'dark';

    localStorage.setItem('theme', newTheme);
    changeTheme({ themeStyle: newTheme })
  };

  const themeView = () => {
    return (
      <Tooltip
        title={formatMessage({
          id: themeStyle === 'dark' ? 'gloabal.tips.theme.lightTooltip' : 'gloabal.tips.theme.darkTooltip',
        })}
      >
        <span style={{ paddingRight: width > 900 && 10 }}>
          {createElement(themeStyle === 'dark' ? SunSvg : MoonSvg, {
            onClick: onChangeTheme,
          })}
        </span>
      </Tooltip>
    )
  }

  return (
    <Header className="layout-page-header bg-2" style={{ backgroundColor: token.token.colorBgContainer }}>

      {width > 900 ?
        <div className="logo" style={{ width: collapsed ? 80 : 200 }} onClick={() => navigate("/")}>
          <img src={WebLogo} alt="" style={{ marginRight: collapsed ? '2px' : '20px' }} />
          {!collapsed && <span style={{ fontSize: '16px' }}>NFTFeed</span>}
        </div>
        :
        <div style={{ paddingLeft: 15 }}>
          {themeView()}
        </div>
      }

      <div
        className="layout-page-header-main"
        style={{
          paddingRight: "0px"
        }}
      >

        <div onClick={toggle}>
          <span id="sidebar-trigger">{collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</span>
        </div>

        <div className="actions">

          {width > 900 ?
            <>
              {themeView()}
              <FullScreen />
            </>
            :
            <>
              {/* {width <= 900 &&
                <ChainSelector />
              } */}
              <div className="logo" style={{ width: 80 }} onClick={() => navigate("/")}>
                <img src={WebLogo} alt="" />
              </div>
            </>

          }

          {/* <HeaderNoticeComponent /> */}
          {/* <Dropdown
            menu={{
              // onClick: info => selectLocale(info),
              items: [
                {
                  key: 'zh_CN',
                  icon: <ZhCnSvg />,
                  // disabled: locale === 'zh_CN',
                  label: '简体中文',
                },
                {
                  key: 'en_US',
                  icon: <EnUsSvg />,
                  // disabled: locale === 'en_US',
                  label: 'English',
                },
              ],
            }}
          >
            <span>
              <LanguageSvg id="language-change" />
            </span>
          </Dropdown> */}

          <ChainSelector />

          <AccountWallet />

        </div>

      </div>

    </Header>
  );
}
export default MainHeader;
