import { DownOutlined } from '@ant-design/icons'
import { useWeb3React } from '@web3-react/core';
import { Dropdown, Button } from 'antd'
import type { MenuProps } from "antd";
import MenuItem from 'antd/es/menu/MenuItem';
import React, { FC, useEffect, useState } from 'react'
import { configEnv } from '~/@config';
import { EVM_CHAIN_LIST } from '~/@config/chain-list';
import { useSwitchChain } from '~/hooks/useSwitchChain';
import { useWindowSize } from '~/hooks/useWindowSize';
const { EVM_CHAINS, applyChainIds } = configEnv();



type MenuItem = Required<MenuProps>["items"][number];

type IChainSelectorProps = {
}

const ChainSelector: FC<IChainSelectorProps> = (props: IChainSelectorProps) => {
  const { width } = useWindowSize()
  const switchChain = useSwitchChain();
  const { chainId, isActive } = useWeb3React();
  const [selected, setSelected] = useState<MenuItem>();
  const [chainItemLayout, setChainItemLayout] = useState<JSX.Element>();

  const renderChainItem = ({ logo = "", dislayName }: { logo?: string; dislayName: string }) => {
    return <div style={{ display: "inline-flex", alignItems: "center" }}>
      <img src={logo || ""} alt={dislayName} style={{ width: "25px", height: "25px", borderRadius: "4px", marginRight: "5px" }} />
    </div>
  }

  useEffect(() => {
    if (!chainId || !applyChainIds.includes(chainId)) {
      return undefined;
    }
    const { logo, chainName, dislayName } = EVM_CHAIN_LIST[chainId]
    setChainItemLayout(renderChainItem({ logo, dislayName: dislayName || chainName }));

    return;
  }, [chainId]);

  const items: MenuProps["items"] = EVM_CHAINS.map(({ dislayName, chainId, chainName, logo, }) => {
    return {
      label: dislayName || chainName || "",
      key: chainId.toString(),
      icon: renderChainItem({ logo, dislayName: dislayName || chainName })
    }
  })

  useEffect(() => {
    if (!chainId) return undefined;
    setSelected(items.find((item) => item?.key === chainId.toString()));
    return;
  }, [chainId]);

  const onSwitchChange: MenuProps["onClick"] = async ({ key }) => {

    await switchChain(parseInt(key));
  };
  if (!chainId || !isActive) return null;
  return (
    <Dropdown
      menu={{
        onClick: onSwitchChange,
        items,
      }}
    >
      <Button style={{ margin: "0px", padding: "0px", border: "1px solid transparent", boxShadow: "none" }}>
        {!selected && <span style={{ marginLeft: "5px" }}>Select Chain</span>}
        {selected && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingTop: "5px" }}>{chainItemLayout}</span>
            {width > 900 &&
              <span style={{ marginRight: "10px" }}>
                {/* @ts-expect-error title is a valid object*/}
                {selected?.label}
              </span>
            }
          </div>
        )}
        <DownOutlined />
      </Button>
    </Dropdown>
  )
}
export default ChainSelector;
