import { BigNumberish, utils } from "ethers"


export const parseAmountToken = (amount: string | number, decimal: number = 18) => {
  return utils.parseUnits(amount.toString(), decimal)
}
export const formatAmountToken = (amount: BigNumberish, decimal: number = 18) => {
  return utils.formatUnits(amount, decimal)
}


export function stringDateToUTCDate(dateStr: string) {
  const inputDate = new Date(dateStr);
  return new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60000);
}

export const formatMoney = (value: string | undefined) => {
  if (!value) {
    return "";
  }
  if (value.charAt(0) === ".") {
    return `0${value}`;
  }
  value = value
    .replace(/\,/g, "") ?? "0"
  const arrStr = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,").split(".");

  if (arrStr.length < 2) {
    return arrStr[0];
  }
  return `${arrStr[0]}.${arrStr[1].replace(/\,/g, "")}`

}

export function equalAddress(addressA: string, addressB: string) {
  return addressA.toLowerCase() === addressB.toLowerCase();
}


