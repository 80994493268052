






import { Button, Col, DatePicker, Descriptions, Form, InputNumber, Row, Spin, theme, Image, Divider, Input, Typography, Tabs, List } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toastService } from '~/services';
import adminEventService from '~/services/admin-event.service';
import { ApiException } from '~/@core/dto';
import { AndroidOutlined, AppleOutlined, CheckOutlined, CopyOutlined, DownCircleFilled, DownloadOutlined } from '@ant-design/icons';
import BaseView from '~/components/BaseView';
import { useVaultDetail } from '../VaultDetailView/hooks/useVaultDetail';
import MintNft from '../VaultDetailView/MintNft';
import QuestCard from './QuestCard';
import { useQuestFreeMint } from '~/hooks/useQuestFreeMint';
import { useFreeMint } from '~/hooks/useFreeMint';
import { useWindowSize } from '~/hooks/useWindowSize';

const imageSize = {
  width: '300px', height: '300px'
}




const { Paragraph } = Typography;

window.addEventListener('error', function (e) {
  console.error(e.message);
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});




const FreeMintView: React.FC = () => {
  const { token: { colorBgBase, colorBgTextActive, colorInfoText } } = theme.useToken();
  const { quests } = useQuestFreeMint();
  const { width } = useWindowSize();

  const { mint, tokenUri, isLoading, claimed } = useFreeMint();




  return (
    <BaseView>
      <div style={{ width: '100%' }}>
        <div>
          <Row >
            <Divider />
            <Col
              xs={24} sm={24} md={12} lg={12} xl={6}
              style={{ textAlign: "center" }}
            >
              <Image
                width={imageSize.width}
                height={imageSize.height}
                loading='lazy'
                src={tokenUri}
                preview={false}
              />
            </Col>
            <Col
              xs={24} sm={24} md={24} lg={24} xl={18}
            >
              <Divider style={{ borderWidth: 0 }} />
              <Col span={24} >
                <p style={{
                  margin: "0px",
                  fontSize: "36px",
                  lineHeight: "normal",
                  fontFamily: "Jost-Bold",
                  color: colorInfoText,
                  textAlign: width <= 900 ? 'center' : "left"
                }}>
                  Rewards: Join Our Journey,Win DOO DOO token and Mint NFT!
                </p>

                <Row style={{ color: '#fff', alignItems: "center", textAlign: width <= 900 ? "center" : "left", justifyContent: width <= 900 ? "center" : "start" }}>

                  <p
                    style={{
                      margin: "5px",
                      color: "#000",
                      fontSize: "14px",
                      fontFamily: "Jost-Bold",
                      padding: "6px 12px",
                      borderRadius: "26px",
                      background: "#f5ffce",
                      border: "2px solid #caff04",
                    }}
                  >
                    Ongoing
                  </p>

                  <p
                    style={{
                      margin: "5px",
                      color: "#000",
                      fontSize: "14px",
                      fontFamily: "Jost-Bold",
                      padding: "6px 12px",
                      borderRadius: "26px",
                      background: "#f5ffce",
                      border: "2px solid #caff04",
                    }}
                  >
                    2023/06/25 14:00 ~ 2023/06/30 14:00 (UTC+00:00)
                  </p>

                </Row>
              </Col>

              <Col>
                <List
                  style={{ height: '100%', }}
                  loading={false}
                  pagination={false}
                  grid={{
                    column: 1,
                  }}
                  dataSource={quests}
                  renderItem={(item) => (
                    <List.Item>
                      <QuestCard  {...item} />
                    </List.Item>
                  )}
                />
              </Col>
              <Row>
                <Button
                  disabled={quests.filter(v => !v.claimed).length > 0 || claimed}
                  loading={isLoading}
                  onClick={mint}
                  type="primary" htmlType="button" size="large"
                  style={{ width: width <= 900 ? "100%" : "280px" }}
                >
                  {claimed ? "Claimed" : "Mint Now"}
                </Button>
              </Row>
              <Divider />
            </Col>
          </Row>
        </div>

      </div>
    </BaseView>
  );
};

export default FreeMintView;
