
import { FieldTimeOutlined, HomeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';
import { IRouter } from '~/routers';
import HomeView from './HomeView';
import CreateVaultView from './CreateVaultView';
import VaultDetailView from './VaultDetailView';
import InventoryViewV2 from './InventoryV2View';
import AffMintView from './AffMintView';
import FreeMintView from './FreeMintView';
import UserReferalView from './UserReferalView';
import QuestView from './QuestView';

export const mainRouter: IRouter[] = [
  {
    path: "",
    key: v4(),
    element: <HomeView />,
    title: "Home",
    isMenu: true,
    icon: <HomeOutlined />,
  },

  {
    path: "create-vault",
    key: v4(),
    element: <CreateVaultView />,
    title: "Create Vault",
    isMenu: true,
    icon: <PlusCircleOutlined />,
  },

  {
    path: "vault-detail",
    key: v4(),
    element: <VaultDetailView />,
    title: "Vault Detail",
    isMenu: false,
    icon: <PlusCircleOutlined />,
  },


  {
    path: "aff-mint",
    key: v4(),
    element: <AffMintView />,
    title: "AFF Mint",
    isMenu: false,
    icon: <PlusCircleOutlined />,
  },

  {
    path: "free-mint",
    key: v4(),
    element: <FreeMintView />,
    title: "Free Mint",
    isMenu: true,
    icon: <PlusCircleOutlined />,
  },

  // {
  //   key: v4(),
  //   path: "quest",
  //   element: <QuestView />,
  //   isMenu: true,
  //   icon: <FieldTimeOutlined />,
  //   title: "Quest",
  // },

  {
    path: "inventory",
    key: v4(),
    element: <InventoryViewV2 />,
    title: "Inventory",
    isMenu: false,
    icon: <HomeOutlined />,
  },
  {
    path: "my-referal",
    key: v4(),
    element: <UserReferalView />,
    title: "My referal",
    isMenu: false,
    icon: <HomeOutlined />,
  },
]
