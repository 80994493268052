import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { useState, useCallback, useEffect } from "react";
import { configEnv } from "~/@config";
import { TokenERC721__factory, TokenERC721, FeedVault, FeedVault__factory } from "~/abi";
import { formatAmountToken } from "~/common/block-chain.helper";
const { customSMC } = configEnv();

export const useMyReferal = ({ vaultId }) => {
  const { account, chainId, provider } = useWeb3React();

  const [amount, setAmount] = useState('0')

  const loadData = useCallback(async () => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;


      const amountRef = await vaultCt.amountReferal(vaultId, account);
      setAmount(formatAmountToken(amountRef))

    } catch (error) {

    }
  }, [account, chainId, provider, vaultId])

  useEffect(() => { loadData() }, [loadData])

  return {
    amount
  }
}
