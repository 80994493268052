



import { Avatar, Button, Col, Descriptions, Form, InputNumber, List, Row, Spin, Typography, theme } from 'antd';
import { useCallback, useState } from 'react';
import { toastService } from '~/services';
import BaseView from '~/components/BaseView';
import { useBuyNft } from './hooks/useBuyNft';
import { ITradeNft } from './dto';
import { useBuyNftList } from './hooks/useBuyNftList';
import { NftByAddressV2 } from '~/dto/inventory.dto';


const { Paragraph } = Typography;

window.addEventListener('error', function (e) {
  console.error(e.message);
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});


const BuyNft = (props: ITradeNft) => {
  const { token: { colorPrimary } } = theme.useToken();
  const { selectedTokenIds, setSelectedTokenIds, vaultInfo, loadListNft, nfts } = props;
  const tokenIds = nfts.map(v => v.tokenId);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { isAllowance, approve, targetRedeem, price } = useBuyNft(props);
  const onFinish = async () => {
    try {
      setIsLoading(true);
      const txLink = await targetRedeem(selectedTokenIds);
      setSelectedTokenIds([]);
      await loadListNft();
      toastService.success(txLink);
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  };


  const randomRedeem = useCallback(async () => {
    try {
      setIsLoading(true);
      const idxRand = Math.floor(Math.random() * tokenIds.length);
      const tokenIdRandom = tokenIds[idxRand % tokenIds.length]
      const txLink = await targetRedeem([tokenIdRandom]);
      setSelectedTokenIds([]);
      await loadListNft();
      toastService.success(txLink);
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  }, [loadListNft, setSelectedTokenIds, targetRedeem, tokenIds])


  const renderBtn = useCallback(() => {
    if (!isAllowance) {
      return <Col span={12}> <Button onClick={approve} type="primary" htmlType="button" size="large" style={{ width: "100%" }}>
        Approve
      </Button></Col>
    }
    return (
      <>
        <Col span={12}>
          {isAllowance && <Button disabled={selectedTokenIds.length === 0} type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>
            Target Redeem
          </Button>}
        </Col>
        <Col span={12}>
          {isAllowance && <Button onClick={randomRedeem} type="primary" htmlType="button" size="large" style={{ width: "100%" }}>
            Random Redeem
          </Button>}
        </Col>
      </>
    )

  }, [approve, isAllowance, randomRedeem, selectedTokenIds.length])

  return (
    <BaseView>
      <div style={{ width: '100%' }}>
        <Spin tip="Loading..." spinning={isLoading}  >
          <Row>
            <Col span={24}>
              <List
                grid={{
                  gutter: 24,
                  xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 4,
                  xxl: 6,
                }}
                dataSource={selectedTokenIds}
                renderItem={(tokenId, index) => (
                  <List.Item >
                    <List.Item.Meta
                      avatar={<Avatar size={"small"} src={`https://nftfeed.guru/static/media/logo_no_title.7966dac9.png`} />}
                      title={`#${tokenId}`}
                    //  description="TokenId"
                    />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={24}>
              <Descriptions title={`Price: ${Number(price).toFixed(2)} DOO DOO`} layout="vertical" bordered size='default' >
              </Descriptions>
            </Col>
          </Row>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item >
              <Row gutter={[24, 1]} style={{ paddingTop: 10 }}>

                {renderBtn()}

              </Row>
            </Form.Item>
          </Form>

        </Spin>

      </div>
    </BaseView>
  );
};

export default BuyNft;
