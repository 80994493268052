import { useWeb3React } from "@web3-react/core"
import { Contract } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { FeedVault__factory, FeedVault, TokenERC721__factory, TokenERC721 } from "~/abi";
import { NftByAddressV2 } from "~/dto/inventory.dto";


const { customSMC } = configEnv();

export const useInventoryV2 = () => {
  const { account, chainId, provider } = useWeb3React();
  const [nfts, setNfts] = useState<NftByAddressV2[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadListNftAddressByVault = useCallback(async () => {

    setIsLoading(true);
    try {
      const VAULT_ADDRESS = customSMC[chainId].VAULT_FACTORY;
      const vaultCt = new Contract(
        VAULT_ADDRESS,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;
      const listNftAddress = await vaultCt.viewListNftAddress();

      let outputs: NftByAddressV2[] = [];
      for (const nftAddress of listNftAddress) {
        const tokenERC721Ct = new Contract(
          nftAddress,
          TokenERC721__factory.abi,
          provider
        ) as TokenERC721;

        const [name, symbol, baseUrl] = await Promise.all([
          tokenERC721Ct.name(),
          tokenERC721Ct.symbol(),
          tokenERC721Ct.baseUrl()
        ])

        const { data } = await tokenERC721Ct.viewPageNftInfosOfOwner(account, 1, 100);
        outputs = outputs.concat(data.map(item => ({
          name,
          address: nftAddress,
          symbol,
          owner: account || "",
          tokenId: item.tokenId.toNumber(),
          tokenUri: baseUrl
        })))
      }
      console.log(`-------------------`);
      console.log(outputs);
      console.log(`-------------------`);
      setNfts(outputs);
    } catch (error) {
      console.log(error);

    }
    setIsLoading(false);
  }, [account, chainId, provider])

  useEffect(() => { loadListNftAddressByVault() }, [loadListNftAddressByVault])

  return {
    isLoading,
    nfts
  }
}
