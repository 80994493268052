import { useWeb3React } from "@web3-react/core"
import { Contract } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { FeedVault__factory, FeedVault, TokenERC721__factory, TokenERC721 } from "~/abi";
import { NftByAddressV2 } from "~/dto/inventory.dto";


const { customSMC } = configEnv();

export const useSellNftList = ({ vaultId, address }: { vaultId: number, address: string }) => {
  const { account, chainId, provider } = useWeb3React();
  const [nfts, setNfts] = useState<NftByAddressV2[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const tokenERC721Ct = new Contract(
        address,
        TokenERC721__factory.abi,
        provider
      ) as TokenERC721;

      const [name, symbol, baseUrl] = await Promise.all([
        tokenERC721Ct.name(),
        tokenERC721Ct.symbol(),
        tokenERC721Ct.baseUrl()
      ])

      const { data } = await tokenERC721Ct.viewPageNftInfosOfOwner(account, 1, 100);
      const outputs = data.map(item => ({
        name,
        address,
        symbol,
        owner: account || "",
        tokenId: item.tokenId.toNumber(),
        tokenUri: baseUrl
      }))
      setNfts(outputs);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }, [account, address, chainId, provider])

  useEffect(() => { loadData() }, [loadData])

  return {
    isLoading,
    loadData,
    nfts
  }
}
