


import { useWeb3React } from "@web3-react/core"
import { BigNumber, Contract, constants } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { getExplorer } from "~/@web3-config/evm";
import { FeedVault__factory, FeedVault, ERC20__factory, ERC20 } from "~/abi";
import { formatAmountToken, parseAmountToken } from "~/common/block-chain.helper";
import { VaultInfoReq } from "~/dto/vault.dto";
import { useVaultInfo } from "~/hooks/useVaultInfo";

import { toastService } from "~/services";
import { ITradeNft } from "../dto";
const { MaxUint256, Zero, AddressZero, HashZero } = constants;

const { customSMC } = configEnv();

export const useBuyNft = ({ vaultId, address, selectedTokenIds }: ITradeNft) => {
  const { provider, account, chainId } = useWeb3React();
  const { vaultInfo, loadData: loadVaultInfo } = useVaultInfo({ vaultId: Number(vaultId) });
  const [price, setPrice] = useState("0");
  const [allowanceBusd, setAllowanceBusd] = useState<BigNumber>(BigNumber.from(0))


  const loadAllowance = useCallback(async () => {
    try {
      const { BUSD = "", VAULT_FACTORY } = customSMC[chainId];
      const tokenCt = new Contract(
        BUSD,
        ERC20__factory.abi,
        provider
      ) as ERC20;
      // const signer = provider.getSigner()
      const allowance = await tokenCt.allowance(account, VAULT_FACTORY);
      setAllowanceBusd(allowance);

    } catch (error) {

    }
  }, [account, chainId, provider]);

  const getPrice = useCallback(async () => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;

      const { amountFeed, amountUsd } = await vaultCt.getAmountOutRatePair(vaultId, selectedTokenIds.length)
      setPrice(formatAmountToken(amountUsd))
    } catch (error) {

    }
  }, [chainId, provider, selectedTokenIds.length, vaultId])


  const approve = useCallback(async () => {
    try {
      const { BUSD = "", VAULT_FACTORY } = customSMC[chainId];
      const tokenCt = new Contract(
        BUSD,
        ERC20__factory.abi,
        provider
      ) as ERC20;
      const signer = provider.getSigner()

      const { transactionHash } = await (
        await tokenCt.connect(signer)
          .approve(VAULT_FACTORY, MaxUint256)
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      console.log('--------link TX-----------');
      console.log(txLink);
      console.log('-------------------');
      toastService.success(txLink);
      loadAllowance();
    } catch (error) {
      toastService.handleError(error);
    }
  }, [chainId, loadAllowance, provider])

  const targetRedeem = useCallback(async (tokenIds: number[]) => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;
      const signer = provider.getSigner()

      const { transactionHash } = await (
        await vaultCt.connect(signer)
          .targetRedeem(vaultId, tokenIds)
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      console.log('--------link TX-----------');
      console.log(txLink);
      console.log('-------------------');
      // loadVaultInfo();
      return txLink;
    } catch (error) {
      throw error;
    }
  }, [chainId, provider, vaultId]);


  useEffect(() => { loadAllowance() }, [loadAllowance])
  useEffect(() => { getPrice() }, [getPrice])

  return {
    vaultInfo,
    isAllowance: allowanceBusd.gte(parseAmountToken(vaultInfo.priceNft)),
    allowanceBusd,
    approve,
    targetRedeem,
    price,
  }
}
