



import { Button, Col, DatePicker, Descriptions, Form, InputNumber, Row, Spin, theme, Image, Divider, Input, Typography, Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toastService } from '~/services';
import adminEventService from '~/services/admin-event.service';
import { ApiException } from '~/@core/dto';
import { AndroidOutlined, AppleOutlined, CheckOutlined, CopyOutlined, DownCircleFilled, DownloadOutlined } from '@ant-design/icons';
import BaseView from '~/components/BaseView';
import { useVaultDetail } from '../VaultDetailView/hooks/useVaultDetail';
import MintNft from '../VaultDetailView/MintNft';
import { formatMoney } from '~/common/block-chain.helper';

const imageSize = {
  width: '300px', height: '300px'
}




const { Paragraph } = Typography;

window.addEventListener('error', function (e) {
  console.error(e.message);
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});




const AffMintView: React.FC = () => {
  const { } = theme.useToken();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const vaultId = searchParams.get("vaultId");
  const referal = searchParams.get("referal") || "";

  console.log(`-------------------`);
  console.log({ vaultId, referal });
  console.log(`-------------------`);

  const { vaultInfo,
    tokenUri,
  } = useVaultDetail({ vaultId: Number(vaultId) });

  const [activeKey, setActiveKey] = useState("BUY");


  const renderVaultInfo = useCallback(() => {
    return (
      <Descriptions column={7} title="Vault infomation" layout="vertical" bordered size='default' >
        <Descriptions.Item label="Name"> {vaultInfo.name} </Descriptions.Item>
        {/* <Descriptions.Item label="NFT Address"> <Paragraph ellipsis copyable={{
          icon: [<CopyOutlined key="copy-icon" />, <CheckOutlined key="copied-icon" />],
          tooltips: ['Copy', 'Copy success'],
        }}>{vaultInfo.nft}</Paragraph></Descriptions.Item> */}


        <Descriptions.Item label="NFT Address"> {vaultInfo.nft} </Descriptions.Item>

        <Descriptions.Item label="Min Qty Mint Allowed To Sell"> {vaultInfo.minQtyMintAllowedToSell}</Descriptions.Item>

        <Descriptions.Item label="NFT Price Mint">{`${formatMoney(vaultInfo.priceNft)} DOO DOO`}</Descriptions.Item>

        <Descriptions.Item label="Total NFT mint">{vaultInfo.totalMint}</Descriptions.Item>
        <Descriptions.Item label="Warranty Fee">{vaultInfo.warrantyFee}%</Descriptions.Item>
        <Descriptions.Item label="Referal bonus">{vaultInfo.referalFee}%</Descriptions.Item>

      </Descriptions>
    )
  }, [vaultInfo.minQtyMintAllowedToSell, vaultInfo.name, vaultInfo.nft, vaultInfo.priceNft, vaultInfo.referalFee, vaultInfo.totalMint, vaultInfo.warrantyFee])



  return (
    <BaseView>
      <div style={{ width: '100%' }}>
        <div>

          <Row>
            <Col span={24}>
              {renderVaultInfo()}
            </Col>
            <Divider />
            <Col span={6}>
              <Image
                width={imageSize.width}
                height={imageSize.height}
                loading='lazy'
                src={tokenUri}
                preview={false}
              />
            </Col>
            <Col span={8}>

              <MintNft vaultId={Number(vaultId)} referal={referal} />
            </Col>
          </Row>
        </div>
        <Divider />
      </div>
    </BaseView>
  );
};

export default AffMintView;
