import { Button, Col, DatePicker, Descriptions, Form, InputNumber, Row, Spin, theme, Image, Divider, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toastService, uploadApiService } from '~/services';
import adminEventService from '~/services/admin-event.service';
import { ApiException } from '~/@core/dto';
import { DownCircleFilled, DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import BaseView from '~/components/BaseView';
import { useCreateVault } from './hooks/useCreateVault';
import { VaultInfoReq } from '~/dto/vault.dto';
import DefUpload from '~/components/DefUpload';
import { useWindowSize } from '~/hooks/useWindowSize';



const CreateVaultView: React.FC = () => {
  const { width } = useWindowSize();
  const { token: { colorBgBase, colorBgTextActive } } = theme.useToken();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [banner, setBanner] = useState("");


  // const [vaultInfo, setVaultInfo] = useState({
  //   name: "",
  //   symbol: "",
  //   baseUrlNft: "",
  //   priceNft: 0,
  //   minQtyMintAllowedToSell: 0
  // })

  const { create } = useCreateVault();

  const onFinish = async (values: VaultInfoReq) => {
    const { warrantyExpirationDate, warrantyFee, referalFee, ...resItem } = values;

    const body = {
      ...resItem,
      warrantyFee: warrantyFee || 0,
      referalFee: referalFee || 0,
      warrantyExpirationDate: new Date(warrantyExpirationDate)
    }

    console.log(`-------------------`);
    console.log(body);
    console.log(`-------------------`);
    try {
      setIsLoading(true);
      const linkTx = await create(body);
      toastService.success(linkTx);
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  };

  const renderInfoPromotion = useCallback(() => {
    return (
      <Descriptions column={4} title="Create vault" layout="vertical" bordered size='default' >
        {/* <Descriptions.Item label="Name">{eventData.name}</Descriptions.Item> */}
      </Descriptions>
    )
  }, []);


  return (
    <BaseView>
      <div style={{ width: '100%' }}>
        <Spin tip="Loading..." spinning={isLoading}  >
          <div style={{ paddingBottom: 15 }}>
            {renderInfoPromotion()}
          </div>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Row gutter={[24, 2]}>

              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >
                <Form.Item
                  label="NFT Name"
                  name="name"
                  rules={[
                    { required: true, message: 'Please input your Name!' },
                    { type: "string", max: 10, message: "Max 10 characters only & no special character" },
                  ]}
                >
                  <Input placeholder='*Max 10 characters only & no special character' />
                </Form.Item>
              </Col>

              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >
                <Form.Item
                  label="NFT Symbol"
                  name="symbol"
                  rules={[
                    { required: true, message: 'Please input your Symbol!' },
                    { type: "string", max: 5, message: "Max 5 characters only & no special character" },
                  ]}
                >
                  <Input placeholder='*Max 5 characters & no special character' />
                </Form.Item>
              </Col>

              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >
                <Form.Item
                  label="NFT Price (DOO DOO)"
                  name="priceNft"
                  rules={[
                    { required: true, message: 'Please input your NFT Price!' },
                    { type: "number", min: 20_000, message: 'No less than 20,000 DOO DOO' },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} min={1} placeholder='*No less than 20,000 DOO DOO' />
                </Form.Item>
              </Col>
              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >
                <Form.Item
                  label={
                    <>
                      <Row>
                        <Col span={24}>
                          {"Min qty mint allowed to sell"}
                        </Col>
                        {/* <Col span={24}>
                          <p
                            style={{
                              color: "#ccc",
                              fontSize: "13px",
                              lineHeight: "20px",
                              textAlign: "left",
                              fontFamily: "Jost-Medium",
                              opacity: "0.8",
                              margin: "-2px 0px 0px",
                              fontStyle: "italic",
                            }}
                          >
                            *Vault Owner must mint at least 10 NFT to be eligible to display the Vault at Home page.
                          </p>
                        </Col> */}
                      </Row>
                      <div>

                      </div>
                      <div>

                      </div>
                    </>
                  }
                  name="minQtyMintAllowedToSell"
                  rules={[
                    { required: true, message: 'Please input your min qty!' },
                    { type: "number", min: 10, message: "Vault Owner must mint at least 10 NFT" }
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} placeholder='*Vault Owner must mint at least 10 NFT to be eligible to display the Vault at Home page' />
                </Form.Item>
              </Col>




              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >
                <Form.Item
                  label="Warranty Expiration Date"
                  name="warrantyExpirationDate"
                  rules={[{ required: true, message: 'Please input your min warranty Expiration Date!' }]}
                >
                  <DatePicker showTime={false} style={{ width: '100%' }} placeholder='*NFT can’t be returned back to Vault after the expiration date' />
                </Form.Item>
              </Col>

              {/* <Col span={8}>
                <Form.Item
                  label="NFT Base Uri"
                  name="baseUrlNft"
                  rules={[{ required: true, message: 'Please input your NFT Base Uri!' }]}
                >
                  <Input placeholder='*Insert your image photo - 891x892 pixels' />
                </Form.Item>
              </Col> */}
              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >
                <Form.Item

                  label="NFT Base Uri"
                  name="baseUrlNft"
                  getValueProps={(value) => ({ value })}
                >
                  <DefUpload
                    buttonUploadLabel="Insert your image photo - 512x512 pixels"
                    name="NFT Uri"
                    value={banner}
                    accept="image/*"
                    requestUpload={async (file) => {
                      const formData = new FormData();
                      formData.append("file", file);
                      try {
                        const res = await uploadApiService.uploadFile(
                          "https://qa.cdn.nftfeed.guru/api/public/resources/upload",
                          formData
                        );
                        return (res?.fullUrl as string || "").replace("api.nft-square.io", "qa.cdn.nftfeed.guru");
                      } catch (err) {
                      }
                    }}
                    listType="picture" />

                </Form.Item>
              </Col>

              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >
                <Form.Item
                  label={
                    <Row>
                      <Col>
                        {"Warranty Fee % (+ 5% system fee)"}
                      </Col>
                      <Col>
                        <p
                          style={{
                            color: "#ccc",
                            fontSize: "13px",
                            lineHeight: "20px",
                            textAlign: "left",
                            fontFamily: "Jost-Medium",
                            opacity: "0.8",
                            margin: "0px 0px 0px",
                            fontStyle: "italic"
                          }}
                        >
                          *NFT return fee set by Vault Owner , Another 5%return fee will be charged automatically by NFTFeed platform.
                        </p>
                      </Col>
                    </Row>
                  }
                  name="warrantyFee"
                  rules={[
                    { required: false, message: 'Please input your min warranty warranty Fee!' },
                  ]}
                >

                  <InputNumber style={{ width: '100%' }} min={1} placeholder='' />
                </Form.Item>
              </Col>
              <Col
                xs={24} sm={24} md={24} lg={12} xl={8}
              >

                <Form.Item
                  label={
                    <Row>
                      {"Referal user sharing % (+ 10% System + 10% Marketing)"}
                      <Col>
                        <p
                          style={{
                            color: "#ccc",
                            fontSize: "13px",
                            lineHeight: "20px",
                            textAlign: "left",
                            fontFamily: "Jost-Medium",
                            opacity: "0.8",
                            margin: "0px 0px 0px",
                            fontStyle: "italic"
                          }}
                        >
                          *Bonus sharing set by Vault Owner for NFT referrer.
                          <br />
                          *10% fee for system & 10% for marketing will be charged automatically by NFTFeed platform.
                        </p>
                      </Col>
                    </Row>
                  }
                  name="referalFee"
                  rules={[
                    { required: false, message: 'Please input your min warranty warranty Fee!' },
                  ]}
                >

                  <InputNumber style={{ width: '100%' }} min={1} placeholder='' />
                </Form.Item>
              </Col>


            </Row>
            <Form.Item >
              <Row>
                <Button type="primary" htmlType="submit" size="large" style={{ width: width <= 900 ? "100%" : "280px" }}>
                  Create
                </Button>
              </Row>

            </Form.Item>
          </Form>
        </Spin>

      </div>
    </BaseView >
  );
};

export default CreateVaultView;
