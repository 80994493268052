



import { Button, Col, Descriptions, Form, InputNumber, Row, Spin, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { toastService } from '~/services';
import BaseView from '~/components/BaseView';
import { useVaultDetail } from './hooks/useVaultDetail';
import { parseAmountToken } from '~/common/block-chain.helper';
import { BigNumber } from 'ethers';
import { useWindowSize } from '~/hooks/useWindowSize';



const { Paragraph } = Typography;

window.addEventListener('error', function (e) {
  console.error(e.message);
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});


const MintNft = ({ vaultId, referal = "" }: { vaultId: number, referal?: string }) => {
  const { width } = useWindowSize()
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [allowanceInput, setAllowanceInput] = useState(BigNumber.from(0));
  const { approve, mint, vaultInfo, allowanceBusd } = useVaultDetail({ vaultId: Number(vaultId) });

  const onFinish = async ({ qty }: { qty: number }) => {
    try {
      setIsLoading(true);
      const txLink = await mint({ qty, referalAddress: referal });
      toastService.success(txLink);
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  };

  const loadAllowanceInput = useCallback((qty: string | number) => {
    try {
      if (!qty) {
        qty = 0;
      }
      qty = Number(qty);
      const priceNft = parseAmountToken(vaultInfo.priceNft);
      const allowanceInput = priceNft.mul(qty);
      setAllowanceInput(allowanceInput);
    } catch (error) {

    }
  }, [vaultInfo.priceNft])


  const isAllowance = allowanceBusd.gte(allowanceInput);

  return (
    <BaseView>
      <div style={{ width: '100%' }}>
        <Spin tip="Loading..." spinning={isLoading}  >

          <Form
            form={form}

            layout="vertical"
            onFinish={onFinish}
          >
            <Descriptions column={7} title={`Mint ${vaultInfo.name} NFT`} layout="vertical" bordered size='default' >
              {referal && <Descriptions.Item label="Referal code"> {referal || ""} </Descriptions.Item>}
            </Descriptions>
            <Row gutter={[24, 2]}>
              <Col
                xs={24} sm={24} md={24} lg={24} xl={24}
              >
                <Form.Item
                  label="Qty"
                  name="qty"

                  rules={[{ required: true, message: 'Please input your min qty!' }]}
                >
                  <InputNumber style={{ width: '100%' }} min={1} onChange={loadAllowanceInput} />
                </Form.Item>
              </Col>
              <Col
                xs={24} sm={24} md={24} lg={24} xl={24}
                style={{ paddingTop: 10 }}
              >
                <Form.Item>
                  {isAllowance && <Button type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>
                    Mint Now
                  </Button>}
                  {!isAllowance && <Button onClick={approve} type="primary" htmlType="button" size="large" style={{ width: "100%" }}>
                    Approve
                  </Button>}
                </Form.Item>
              </Col>
            </Row>
          </Form>

        </Spin>

      </div>
    </BaseView >
  );
};

export default MintNft;
