import { useWeb3React, } from "@web3-react/core";
import { Connector } from "@web3-react/types";
import { Modal, Divider } from "antd";
import { v4 } from "uuid";
import { coinbaseConnector, evmConnectors, metaMaskConnector, walletConnectConnector } from "~/@web3-config/evm";

import coinbase_Logo from "~/assets/wallets/coinbase_Logo.png";
import metamask_Logo from "~/assets/wallets/metamask_Logo.svg";
import walletconnect_Logo from "~/assets/wallets/walletconnect_Logo.svg";


const styles = {
  modalTitle: {
    marginBottom: "20px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    fontWeight: "700",
    fontSize: "20px"
  },
  connectButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    paddingBlock: "5px",
    marginBottom: "12px",
    boxShadow: "0 4px 4px rgba(0,0,0,.25),0 0 5px rgba(0,0,0,.25),inset 0 0 10px #fff"
  },
  connectButtonText: {
    fontWeight: "600",
    paddingLeft: "30px"
  }
} as const;



interface ConnectModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function ConnectButton({ label, image, onClick }: { label: string; image: string; onClick: () => void }) {
  return (
    <button style={styles.connectButton} key={label} onClick={onClick}>
      <span style={styles.connectButtonText}>{label}</span>
      <img src={image} width={50} height={50} alt="web3_wallet" />
    </button>
  );
}

const ConnectModal: React.FC<ConnectModalProps> = ({ isModalOpen, setIsModalOpen }) => {
  const activateConnector = async (connector: Connector) => {
    try {
      await connector.activate();
      setIsModalOpen(false)
    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
    }
  };
  return (
    <Modal
      key={"connect-wallet-modal"}
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <div style={styles.modalTitle}>Connect Your Wallet</div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <ConnectButton
          key="MetaMask"
          label="MetaMask"
          image={metamask_Logo}
          onClick={() => activateConnector(metaMaskConnector)}
        />

        <ConnectButton
          key="WalletConnect"
          label="WalletConnect"
          image={walletconnect_Logo}
          onClick={() => activateConnector(walletConnectConnector)}
        />

        <ConnectButton
          key="Coinbase"
          label="Coinbase Wallet"
          image={coinbase_Logo}
          onClick={() => activateConnector(coinbaseConnector)}
        />
        <Divider />
        <div style={{ margin: "auto", fontSize: "15px", marginBottom: "15px" }}>
          Need help installing a wallet?{" "}
          <a
            href="https://metamask.zendesk.com/hc/en-us/articles/360015489471-How-to-Install-MetaMask-Manually"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>
        </div>
        <div style={{ margin: "auto", fontSize: "10px" }}>
          Wallets are provided by External Providers and by selecting you agree to Terms of those Providers. Your access
          to the wallet might be reliant on the External Provider being operational.
        </div>
      </div>
    </Modal>
  );
};

export default ConnectModal;
