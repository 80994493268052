


import { useWeb3React } from "@web3-react/core"
import { BigNumber, Contract, constants } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { getExplorer } from "~/@web3-config/evm";
import { TokenERC721 } from "~/abi";
import { TokenERC721__factory } from "~/abi";
import { FeedVault__factory, FeedVault, ERC20__factory, ERC20 } from "~/abi";
import { FreeMintPool } from "~/abi/FreeMintPool";
import { FreeMintPool__factory } from "~/abi/factories/FreeMintPool__factory";
import { useVaultInfo } from "~/hooks/useVaultInfo";

import { toastService } from "~/services";
const { MaxUint256, Zero, AddressZero, HashZero } = constants;

const { customSMC } = configEnv();

export const useFreeMint = () => {
  const { provider, account, chainId } = useWeb3React();


  const [isLoading, setIsLoading] = useState(false);

  const [tokenUri, setTokenUri] = useState("");

  const [claimed, setClaimed] = useState(false);

  const loadData = useCallback(async () => {
    try {
      const { FREE_MINT_POOL } = customSMC[chainId];
      const poolCt = new Contract(
        FREE_MINT_POOL,
        FreeMintPool__factory.abi,
        provider
      ) as FreeMintPool;
      const nftAddress = await poolCt.erc721();

      const nftCt = new Contract(nftAddress, TokenERC721__factory.abi, provider) as TokenERC721;
      const baseUrl = await nftCt.baseUrl();
      setTokenUri(baseUrl);
    } catch (error) {

    }

  }, [chainId, provider]);


  const getStatusClaim = useCallback(async () => {
    try {
      const { FREE_MINT_POOL } = customSMC[chainId];
      const poolCt = new Contract(
        FREE_MINT_POOL,
        FreeMintPool__factory.abi,
        provider
      ) as FreeMintPool;
      const isClaimed = await poolCt.usersClaimed(account);
      setClaimed(isClaimed);
    } catch (error) {

    }

  }, [account, chainId, provider]);




  useEffect(() => {
    loadData();
  }, [loadData])

  useEffect(() => {
    getStatusClaim();
  }, [getStatusClaim])

  const mint = useCallback(async () => {
    setIsLoading(true)
    try {
      const { FREE_MINT_POOL } = customSMC[chainId];
      const poolCt = new Contract(
        FREE_MINT_POOL,
        FreeMintPool__factory.abi,
        provider
      ) as FreeMintPool;
      const signer = provider.getSigner()

      const { transactionHash } = await (
        await poolCt.connect(signer)
          .claim()
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      toastService.success(txLink);
      getStatusClaim();
    } catch (error) {
      toastService.handleError(error)
    }
    setIsLoading(false)
  }, [chainId, getStatusClaim, provider]);




  return {
    isLoading,
    tokenUri,
    claimed,
    mint
  }
}
