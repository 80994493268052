import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { FeedVault__factory, FeedVault, ERC20__factory, ERC20 } from "~/abi";
import { formatAmountToken } from "~/common/block-chain.helper";
import { VaultFactoryInfo } from "~/dto/vault.dto";


const { customSMC } = configEnv();

export const useVaulList = () => {
  const { account, chainId, provider } = useWeb3React();

  const [isLoading, setIsLoading] = useState(false);
  const [vaultFactoryInfo, setVaultFactoryInfo] = useState<VaultFactoryInfo>({
    vaultIndex: 0,
    feedToken: "",
    usdToken: "",
    pair: ""
  });


  const [vaultsInfo, setVaultsInfo] = useState<{
    manager: string;
    totalMint: number;
    minQtyMintAllowedToSell: number;
    vaultId: number;
  }[]>([]);

  const [tvl, setTvl] = useState("0");

  const loadData = useCallback(async () => {
    try {
      const { BUSD = "", VAULT_FACTORY } = customSMC[chainId];
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;

      const [
        vaultIndex,
        feedToken,
        usdToken,
        pair,
      ] = await Promise.all([
        vaultCt.vaultIndex(),
        vaultCt.feedToken(),
        vaultCt.usdToken(),
        vaultCt.getPair()
      ]);

      const tokenCt = new Contract(
        BUSD,
        ERC20__factory.abi,
        provider
      ) as ERC20;
      const tvlBig = await tokenCt.balanceOf(pair);

      setTvl(formatAmountToken(tvlBig.mul(2)))

      setVaultFactoryInfo({
        vaultIndex: vaultIndex.toNumber(),
        feedToken,
        usdToken,
        pair,
      })

    } catch (error) {
      console.log(error);

    }
  }, [chainId, provider])


  const loadVaultsInfo = useCallback(async () => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const vaultIds = Array.from({ length: vaultFactoryInfo.vaultIndex }).map((_, idx) => idx + 1);
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;

      const data = await Promise.all(vaultIds.map(vaultId => vaultCt.containerVault(vaultId).then(({ manager, totalMint, minQtyMintAllowedToSell }) => {
        return {
          manager,
          totalMint: totalMint.toNumber(),
          minQtyMintAllowedToSell: minQtyMintAllowedToSell.toNumber(),
          vaultId
        }
      })));

      setVaultsInfo(data)

    } catch (error) {
      console.log(error);

    }


  }, [chainId, provider, vaultFactoryInfo.vaultIndex])

  useEffect(() => { loadData() }, [loadData]);
  useEffect(() => { loadVaultsInfo() }, [loadVaultsInfo])

  return {
    isLoading,
    vaultFactoryInfo,
    vaultIds: vaultsInfo
      .filter(v => v.totalMint >= v.minQtyMintAllowedToSell || v.manager === account)
      .filter(v => v.vaultId !== 29)
      .map(v => v.vaultId),
    tvl,
  }
}
