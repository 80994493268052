import { useWeb3React } from "@web3-react/core"
import { Contract } from "ethers";
import { useCallback } from "react";
import { configEnv } from "~/@config";
import { getExplorer } from "~/@web3-config/evm";
import { FeedVault__factory, FeedVault } from "~/abi";
import { parseAmountToken } from "~/common/block-chain.helper";
import { VaultInfoReq } from "~/dto/vault.dto";



const { customSMC } = configEnv();

export const useCreateVault = () => {
  const { provider, account, chainId } = useWeb3React();
  const create = useCallback(async ({ name, symbol, baseUrlNft, priceNft, minQtyMintAllowedToSell,
    warrantyExpirationDate,
    warrantyFee = 0,
    referalFee = 0,
  }: VaultInfoReq) => {
    try {
      const VAULT_ADDRESS = customSMC[chainId].VAULT_FACTORY;
      const vaultCt = new Contract(
        VAULT_ADDRESS,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;
      const signer = provider.getSigner()
      const { transactionHash } = await (
        await vaultCt.connect(signer)
          .createVault(
            name,
            symbol,
            baseUrlNft || "https://dapp.nftfeed.guru/static/media/19.6233b4d2.png",
            parseAmountToken(priceNft),
            minQtyMintAllowedToSell,
            Math.floor(Date.withoutTime(warrantyExpirationDate.toUTCDate()).getTime() / 1000),
            warrantyFee * 100,
            referalFee * 100
          )
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      console.log('--------link TX-----------');
      console.log(txLink);
      console.log('-------------------');
      return txLink;

    } catch (error) {
      throw error;
    }
  }, [chainId, provider])


  return {
    create
  }
}
