


import { useWeb3React } from "@web3-react/core"
import { BigNumber, Contract, constants } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { getExplorer } from "~/@web3-config/evm";
import { FeedVault__factory, FeedVault, ERC20__factory, ERC20, TokenERC721__factory, TokenERC721 } from "~/abi";
import { formatAmountToken, parseAmountToken } from "~/common/block-chain.helper";
import { VaultInfoReq } from "~/dto/vault.dto";
import { useVaultInfo } from "~/hooks/useVaultInfo";

import { toastService } from "~/services";
import { ITradeNft } from "../dto";
const { MaxUint256, Zero, AddressZero, HashZero } = constants;

const { customSMC } = configEnv();

export const useSellNft = ({ vaultId, address, selectedTokenIds }: ITradeNft) => {
  const { provider, account, chainId } = useWeb3React();
  const [price, setPrice] = useState("0");
  const [fee, setFee] = useState(0);
  const [isApprovedForAll, setIsApprovedForAll] = useState(false);


  const loadAllowance = useCallback(async () => {
    try {
      const { BUSD = "", VAULT_FACTORY } = customSMC[chainId];
      const tokenERC721Ct = new Contract(
        address,
        TokenERC721__factory.abi,
        provider
      ) as TokenERC721;
      // const signer = provider.getSigner()
      const allowance = await tokenERC721Ct.isApprovedForAll(account, VAULT_FACTORY)
      setIsApprovedForAll(allowance);

    } catch (error) {

    }
  }, [account, address, chainId, provider]);



  const getPrice = useCallback(async () => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;


      const { amountFeed, amountUsd } = await vaultCt.getAmountOut(vaultId, selectedTokenIds.length,);

      const { warrantyFee } = await vaultCt.containerVault(vaultId);
      setFee(warrantyFee.toNumber() / 100)
      setPrice(formatAmountToken(amountFeed))
    } catch (error) {

    }
  }, [chainId, provider, selectedTokenIds.length, vaultId])


  const approve = useCallback(async () => {
    try {
      const { BUSD = "", VAULT_FACTORY } = customSMC[chainId];
      const tokenERC721Ct = new Contract(
        address,
        TokenERC721__factory.abi,
        provider
      ) as TokenERC721;
      const signer = provider.getSigner()

      const { transactionHash } = await (
        await tokenERC721Ct.connect(signer)
          .setApprovalForAll(VAULT_FACTORY, true)
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      console.log('--------link TX-----------');
      console.log(txLink);
      console.log('-------------------');
      toastService.success(txLink);
      loadAllowance();
    } catch (error) {
      toastService.handleError(error);
    }
  }, [address, chainId, loadAllowance, provider])

  const sell = useCallback(async (tokenIds: number[]) => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;
      const signer = provider.getSigner();


      console.log(`--------sellByVaultId-----------`);
      console.log(tokenIds);
      console.log(`-------------------`);

      const { transactionHash } = await (
        await vaultCt.connect(signer)
          .sellByVault(vaultId, tokenIds)
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      console.log('--------link TX-----------');
      console.log(txLink);
      console.log('-------------------');
      // loadVaultInfo();
      return txLink;
    } catch (error) {
      throw error;
    }
  }, [chainId, provider, vaultId]);

  const returnsGuaranteed = useCallback(async (tokenIds: number[]) => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;
      const signer = provider.getSigner();


      console.log(`--------sellByVaultId-----------`);
      console.log(tokenIds);
      console.log(`-------------------`);

      const { transactionHash } = await (
        await vaultCt.connect(signer)
          .returnsGuaranteed(vaultId, tokenIds)
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      console.log('--------link TX-----------');
      console.log(txLink);
      console.log('-------------------');
      // loadVaultInfo();
      return txLink;
    } catch (error) {
      throw error;
    }
  }, [chainId, provider, vaultId]);


  useEffect(() => { loadAllowance() }, [loadAllowance]);

  useEffect(() => { getPrice() }, [getPrice])


  return {

    isAllowance: isApprovedForAll,
    approve,
    sell,
    price,
    returnsGuaranteed,
    fee,
  }
}
