import { FC, Suspense, useCallback, useEffect } from 'react';
import { ConfigProvider, Spin } from "antd";
import moment from 'moment';
import { Connector } from "@web3-react/types";
import AppRouter from './views/AppRouter';
import ActivityIndicator from './components/ActivityIndicator';
import { useThemeStore } from './stores/themeStore';
import { IntlProvider } from 'react-intl';
import { localeConfig, LocaleFormatter } from './locales';
import { LOCALE_RESOURCE } from './locales/locale.resource';
import { metaMaskConnector } from './@web3-config/evm';


interface IProps {

}
const isFirstLoading = false;
const App: FC<IProps> = (props: IProps) => {

  const { antdValue, momentValue } = LOCALE_RESOURCE["en"];
  const { changeTheme } = useThemeStore();


  const activateConnector = useCallback(async (connector: Connector) => {
    try {
      await connector.activate();
    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
    }
  }, []);

  useEffect(() => {
    moment.locale(momentValue);

  }, [changeTheme, momentValue]);

  useEffect(() => {
    activateConnector(metaMaskConnector)
  }, [activateConnector]);

  const { themConfig } = useThemeStore();
  return (
    <ConfigProvider
      theme={{
        ...themConfig
      }}
      locale={antdValue}>
      <IntlProvider locale={'en'} messages={localeConfig["en_US"]}>

        <Suspense fallback={null}>
          <Spin
            spinning={isFirstLoading}
            className="app-loading-wrapper"
            tip={<LocaleFormatter id="gloabal.tips.loading" />}
          ></Spin>
          {isFirstLoading ? <ActivityIndicator /> : <AppRouter />}
        </Suspense>

      </IntlProvider>

    </ConfigProvider>
  )
}
export default App;
