



import { QuestionCircleOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { SelectInfo } from 'rc-menu/lib/interface';

import { FC } from 'react'
import { MenuLayoutItem, useLayoutConfig } from '~/stores/layoutConfig';


type IMainMenuProps = {
  menus: MenuLayoutItem[];
  openKeys?: string[];
  selectedKey: string;
}

const MainMenu: FC<IMainMenuProps> = (props: IMainMenuProps) => {

  const { menus, openKeys, selectedKey } = props;
  const { device, setCollapsed, flatLayoutResource, addTab, setOpenKeys } = useLayoutConfig();
  const onMenuClick = (item: SelectInfo) => {
    const tab = flatLayoutResource.find(v => v.key === item.key);
    addTab(tab);
    if (device !== 'DESKTOP') {
      setCollapsed(true);
    }
  };



  const getTitie = (view: MenuLayoutItem) => {
    const { icon = <QuestionCircleOutlined />, title = "NO TITLE" } = view;
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span>{title}</span>
      </span>
    );
  };

  const mapMenus = (menus: MenuLayoutItem[]): ItemType[] => {
    const outputs = new Array<ItemType>();
    for (const item of menus) {
      const { children = [], path } = item;
      const input: ItemType = {
        key: item.key,
        label: getTitie(item),
      }
      if (children.length > 0) {
        const child = mapMenus(children);
        Object.assign(input, {
          children: child
        })
      }
      outputs.push(input);
    }
    return outputs;
  }

  return (
    <Menu
      mode="inline"
      selectedKeys={[selectedKey]}
      openKeys={openKeys}
      onOpenChange={setOpenKeys}
      onSelect={k => onMenuClick(k)}
      className="layout-page-sider-menu text-2"
      items={mapMenus(menus)}
    ></Menu>
  )
}
export default MainMenu;
