

import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React, { FC } from 'react'


type ITextCopyProps = {
  showCopy?: boolean;
  children?: React.ReactNode;
}

const TextCopy = ({ showCopy = false, children }: ITextCopyProps) => {
  if (showCopy) {
    return (
      <Paragraph ellipsis copyable={{
        icon: [<CopyOutlined key="copy-icon" />, <CheckOutlined key="copied-icon" />],
        tooltips: ['Copy', 'Copy success']
      }}>{children}</Paragraph>
    )
  }
  return <>
    {children}
  </>;
}
export default TextCopy;
