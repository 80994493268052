import React, { FC } from 'react'
import { useInventoryV2 } from './hooks/useInventoryV2'
import BaseView from '~/components/BaseView';
import { Card, Image, List } from 'antd';
import NftCard from './NftCard';

const { Meta } = Card;

type IInventoryViewV2Props = {
}

const pageSize = 12;

const InventoryViewV2: FC<IInventoryViewV2Props> = (props: IInventoryViewV2Props) => {

  const { nfts, isLoading } = useInventoryV2();
  return (
    <BaseView>
      <List
        style={{ height: '100%' }}
        loading={isLoading}
        grid={{
          gutter: 24,
          xs: 1,
          sm: 2,
          md: 3,
          lg: 3,
          xl: 4,
          xxl: 6,
        }}
        pagination={{
          align: 'end',
          position: 'bottom',
          pageSize,

        }}
        dataSource={nfts}
        renderItem={(item) => (
          <List.Item>
            <NftCard {...item} />
          </List.Item>
        )}
      />

    </BaseView>
  )
}
export default InventoryViewV2;
