
import { useWeb3React } from "@web3-react/core";
import dayjs from "dayjs";
import { Contract } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { getExplorer } from "~/@web3-config/evm";
import { FeedVault__factory, FeedVault, TokenERC721, TokenERC721__factory } from "~/abi";
import { formatAmountToken, parseAmountToken } from "~/common/block-chain.helper";
import { NftByAddress } from "~/dto/inventory.dto";
import { VaultFactoryInfo, VaultInfo } from "~/dto/vault.dto";



const { customSMC } = configEnv();

export const useVaultInfo = ({ vaultId }: { vaultId: number }) => {
  const { account, chainId, provider } = useWeb3React();


  const [isLoading, setIsLoading] = useState(false);
  const [vaultInfo, setVaultInfo] = useState<VaultInfo>({
    name: "",
    manager: "",
    priceNft: "0",
    totalLP: "0",
    minQtyMintAllowedToSell: 0,
    totalMint: 0,
    nft: "",
    warrantyFee: 0,
    referalFee: 0,
    warrantyExpirationDate: '',
  });
  const [tokenUri, setTokenUri] = useState("");

  const [liquidityVault, setLiquidityVault] = useState("0");


  const loadData = useCallback(async () => {
    try {
      const VAULT_ADDRESS = customSMC[chainId].VAULT_FACTORY;
      const vaultCt = new Contract(
        VAULT_ADDRESS,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;
      const {
        name,
        manager,
        priceNft,
        totalLP,
        minQtyMintAllowedToSell,
        totalMint,
        nft,
        warrantyFee,
        referalFee,
        warrantyExpirationDate,
      } = await vaultCt.containerVault(vaultId);
      const tokenERC721Ct = new Contract(
        nft,
        TokenERC721__factory.abi,
        provider
      ) as TokenERC721;



      const [url] = await Promise.all([
        tokenERC721Ct.baseUrl(),
        // vaultCt.getAmountOutByVaulId(totalLP, vaultId)
      ])
      setVaultInfo({
        name,
        manager,
        priceNft: formatAmountToken(priceNft),
        totalLP: formatAmountToken(totalLP),
        minQtyMintAllowedToSell: minQtyMintAllowedToSell.toNumber(),
        totalMint: totalMint.toNumber(),
        nft,
        warrantyFee: warrantyFee.toNumber() / 100,
        referalFee: referalFee.toNumber() / 100,
        warrantyExpirationDate: dayjs(new Date(warrantyExpirationDate.toNumber() * 1000)).format("YYYY-MM-DD")
      });
      setTokenUri(url);
      // setLiquidityVault(formatAmountToken(lp.amountUsd))

    } catch (error) {
      console.log(error);

    }
  }, [chainId, provider, vaultId])


  useEffect(() => { loadData() }, [loadData])

  return {
    isLoading,
    vaultInfo,
    liquidityVault,
    tokenUri,
    loadData
  }
}
