


import { useWeb3React } from "@web3-react/core"
import { BigNumber, Contract, constants } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config";
import { getExplorer } from "~/@web3-config/evm";
import { FeedVault__factory, FeedVault, ERC20__factory, ERC20 } from "~/abi";
import { parseAmountToken } from "~/common/block-chain.helper";
import { useVaultInfo } from "~/hooks/useVaultInfo";

import { toastService } from "~/services";
const { MaxUint256, Zero, AddressZero, HashZero } = constants;

const { customSMC } = configEnv();

export const useVaultDetail = ({ vaultId }: { vaultId: number }) => {
  const { provider, account, chainId } = useWeb3React();
  const { vaultInfo, loadData: loadVaultInfo, liquidityVault, tokenUri } = useVaultInfo({ vaultId: Number(vaultId) });

  const [allowanceBusd, setAllowanceBusd] = useState<BigNumber>(BigNumber.from(0));

  const [selectedTokenIdsBuy, setSelectedTokenIdsBuy] = useState([]);
  const [selectedTokenIdsSell, setSelectedTokenIdsSell] = useState([]);
  const [selectedTokenIdsReturn, setSelectedTokenIdsReturn] = useState([]);


  const loadAllowance = useCallback(async () => {
    try {
      const { BUSD = "", VAULT_FACTORY } = customSMC[chainId];
      const tokenCt = new Contract(
        BUSD,
        ERC20__factory.abi,
        provider
      ) as ERC20;
      // const signer = provider.getSigner()
      const allowance = await tokenCt.allowance(account, VAULT_FACTORY);
      setAllowanceBusd(allowance);

    } catch (error) {

    }
  }, [account, chainId, provider])


  const approve = useCallback(async () => {
    try {
      const { BUSD = "", VAULT_FACTORY } = customSMC[chainId];
      const tokenCt = new Contract(
        BUSD,
        ERC20__factory.abi,
        provider
      ) as ERC20;
      const signer = provider.getSigner()

      const { transactionHash } = await (
        await tokenCt.connect(signer)
          .approve(VAULT_FACTORY, MaxUint256)
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();
      console.log('--------link TX-----------');
      console.log(txLink);
      console.log('-------------------');
      toastService.success(txLink);
      loadAllowance();
    } catch (error) {
      toastService.handleError(error);
    }
  }, [chainId, loadAllowance, provider])

  const mint = useCallback(async ({ qty, referalAddress = AddressZero }: { qty: number, referalAddress?: string }) => {
    try {
      const { VAULT_FACTORY } = customSMC[chainId];
      if (!referalAddress) {
        referalAddress = AddressZero
      }


      const vaultCt = new Contract(
        VAULT_FACTORY,
        FeedVault__factory.abi,
        provider
      ) as FeedVault;
      const signer = provider.getSigner()

      const { transactionHash } = await (
        await vaultCt.connect(signer)
          .mintNftByVault(vaultId, qty, referalAddress)
      ).wait();

      const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();

      loadVaultInfo();
      return txLink;
    } catch (error) {
      throw error;
    }
  }, [chainId, loadVaultInfo, provider, vaultId]);


  useEffect(() => { loadAllowance() }, [loadAllowance])


  return {
    vaultInfo,
    tokenUri,
    liquidityVault,
    allowanceBusd,
    approve,
    mint,
    selectedTokenIdsBuy,
    setSelectedTokenIdsBuy,
    selectedTokenIdsSell,
    setSelectedTokenIdsSell,
    selectedTokenIdsReturn,
    setSelectedTokenIdsReturn
  }
}
