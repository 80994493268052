



import { Button, Col, Descriptions, Row, theme, Divider, Typography, Tabs } from 'antd';
import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CrownOutlined, GiftOutlined, LinkOutlined, RedoOutlined } from '@ant-design/icons';
import BaseView from '~/components/BaseView';
import BuyNft from './BuyNft';
import MintNft from './MintNft';
import SellNft from './SellNft';
import { useVaultDetail } from './hooks/useVaultDetail';
import BuyNftTab from './BuyNftTab';
import SellNftTab from './SellNftTab';
import ReturnNft from './ReturnNft';
import { getBaseUrl, parseParams } from '~/common/utils/common.utils';
import { useWeb3React } from '@web3-react/core';
import TextCopy from '~/components/TextCopy';
import { formatMoney } from '~/common/block-chain.helper';
import { useBuyNftList } from './hooks/useBuyNftList';
import { useSellNftList } from './hooks/useSellNftList';


const { Paragraph } = Typography;

window.addEventListener('error', function (e) {
  console.error(e.message);
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});


const tabData = [
  {
    key: "BUY",
    name: "REDEEM",
    icon: GiftOutlined
  },
  {
    key: "SELL",
    name: "MINT XTOKEN",
    icon: CrownOutlined
  },

  {
    key: "RETURNS",
    name: "RETURNS GUARANTEE NFT",
    icon: RedoOutlined
  },

]


const VaultDetailView: React.FC = () => {
  const { } = theme.useToken();
  const { account } = useWeb3React();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const vaultId = searchParams.get("vaultId");




  const { vaultInfo,
    selectedTokenIdsBuy,
    setSelectedTokenIdsBuy,
    selectedTokenIdsSell,
    setSelectedTokenIdsSell,
    selectedTokenIdsReturn,
    setSelectedTokenIdsReturn
  } = useVaultDetail({ vaultId: Number(vaultId) });


  const { loadData: loadListNftBuy, nfts: nftsBuy, isLoading: isLoadingBuy } = useBuyNftList({

    vaultId: Number(vaultId),
    address: vaultInfo.nft
  });

  const { loadData: loadListNftSell, nfts: nftsSell, isLoading: isLoadingSell } = useSellNftList({
    vaultId: Number(vaultId),
    address: vaultInfo.nft
  });

  // const { loadData: loadListNftReturn, nfts: nftsReturn } = useSellNftList({
  //   vaultId: Number(vaultId),
  //   address: vaultInfo.nft
  // })

  const [activeKey, setActiveKey] = useState("BUY");


  const renderVaultInfo = useCallback(() => {
    const showCopy = process.env.NODE_ENV !== "development";
    return (
      <Descriptions
        column={{ xxl: 5, xl: 5, lg: 5, md: 4, sm: 2, xs: 2 }}
        title="Vault Infomation" layout="vertical" bordered size='default'
      >
        <Descriptions.Item label="Name"> {vaultInfo.name} </Descriptions.Item>
        <Descriptions.Item label="NFT Address">
          <TextCopy showCopy={showCopy}>
            {vaultInfo.nft}
          </TextCopy>
        </Descriptions.Item>
        <Descriptions.Item label="Min Qty Mint Allowed To Sell"> {vaultInfo.minQtyMintAllowedToSell}</Descriptions.Item>
        <Descriptions.Item label="NFT Price Mint">{`${formatMoney(vaultInfo.priceNft)} DOO DOO`}</Descriptions.Item>
        <Descriptions.Item label="Total NFT mint">{vaultInfo.totalMint}</Descriptions.Item>
        <Descriptions.Item label="Warranty Fee">{(vaultInfo.warrantyFee + 5)}%</Descriptions.Item>
        <Descriptions.Item label="Referal bonus">{vaultInfo.referalFee}%</Descriptions.Item>
        <Descriptions.Item label="Expiration date">{vaultInfo.warrantyExpirationDate}</Descriptions.Item>
        <Descriptions.Item label={
          <div style={{ maxWidth: "100%", wordBreak: "break-all" }}>
            {"Your referal link"}
            {vaultInfo.referalFee > 0 &&
              <TextCopy showCopy={showCopy}>
                {` ${getBaseUrl()}/aff-mint?${parseParams({
                  vaultId: vaultId,
                  referal: account,
                })}`}
              </TextCopy>}
          </div>
        }>
          {vaultInfo.referalFee > 0 && <>
            <Button type='primary'
              icon={<LinkOutlined />}
              onClick={() => window.open(`${getBaseUrl()}/aff-mint?${parseParams({
                vaultId: vaultId,
                referal: account,
              })}`)}
            >
              Go to referal link
            </Button>
          </>}

        </Descriptions.Item>
      </Descriptions>
    )
  }, [account, vaultId, vaultInfo.minQtyMintAllowedToSell, vaultInfo.name, vaultInfo.nft, vaultInfo.priceNft, vaultInfo.referalFee, vaultInfo.totalMint, vaultInfo.warrantyExpirationDate, vaultInfo.warrantyFee])


  const renderTabContent = useCallback(() => {
    if (activeKey === "BUY") {
      return <BuyNftTab
        loadListNft={loadListNftBuy}
        nfts={nftsBuy}
        isLoading={isLoadingBuy}
        vaultId={Number(vaultId)}
        address={vaultInfo.nft}
        setSelectedTokenIds={setSelectedTokenIdsBuy}
        selectedTokenIds={selectedTokenIdsBuy}
        vaultInfo={vaultInfo}

      />
    }
    if (activeKey === "SELL") {
      return <SellNftTab
        loadListNft={loadListNftSell}
        nfts={nftsSell}
        isLoading={isLoadingSell}
        vaultId={Number(vaultId)}
        address={vaultInfo.nft}
        setSelectedTokenIds={setSelectedTokenIdsSell}
        selectedTokenIds={selectedTokenIdsSell}
        vaultInfo={vaultInfo}

      />
    }
    return <SellNftTab
      loadListNft={loadListNftSell}
      nfts={nftsSell}
      isLoading={isLoadingSell}
      vaultId={Number(vaultId)}
      address={vaultInfo.nft}
      setSelectedTokenIds={setSelectedTokenIdsReturn}
      selectedTokenIds={selectedTokenIdsReturn}
      vaultInfo={vaultInfo}

    />

  }, [activeKey, isLoadingBuy, isLoadingSell, loadListNftBuy, loadListNftSell, nftsBuy, nftsSell, selectedTokenIdsBuy, selectedTokenIdsReturn, selectedTokenIdsSell, setSelectedTokenIdsBuy, setSelectedTokenIdsReturn, setSelectedTokenIdsSell, vaultId, vaultInfo]);

  const renderTabAction = useCallback(() => {
    if (activeKey === "BUY") {
      return <BuyNft
        nfts={nftsBuy}
        loadListNft={loadListNftBuy}
        vaultId={Number(vaultId)}
        address={vaultInfo.nft}
        setSelectedTokenIds={setSelectedTokenIdsBuy}
        selectedTokenIds={selectedTokenIdsBuy}
        vaultInfo={vaultInfo}
      />
    }
    if (activeKey === "SELL") {
      return <SellNft
        nfts={nftsSell}
        loadListNft={loadListNftSell}
        vaultId={Number(vaultId)}
        address={vaultInfo.nft}
        setSelectedTokenIds={setSelectedTokenIdsSell}
        selectedTokenIds={selectedTokenIdsSell}
        vaultInfo={vaultInfo}
      />
    }

    return <ReturnNft
      nfts={nftsSell}
      loadListNft={loadListNftSell}
      vaultId={Number(vaultId)}
      address={vaultInfo.nft}
      setSelectedTokenIds={setSelectedTokenIdsReturn}
      selectedTokenIds={selectedTokenIdsReturn}
      vaultInfo={vaultInfo}
    />


  }, [activeKey, loadListNftBuy, loadListNftSell, nftsBuy, nftsSell, selectedTokenIdsBuy, selectedTokenIdsReturn, selectedTokenIdsSell, setSelectedTokenIdsBuy, setSelectedTokenIdsReturn, setSelectedTokenIdsSell, vaultId, vaultInfo])

  return (
    <BaseView>
      <div style={{ width: '100%' }}>

        <Row gutter={[24, 1]}>
          <Col
            xs={24} sm={24} md={24} lg={24} xl={18}
          >
            {renderVaultInfo()}
          </Col>
          <Col
            xs={24} sm={24} md={24} lg={24} xl={6}
          >
            <MintNft vaultId={Number(vaultId)} />
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col
            xs={24} sm={24} md={24} lg={24} xl={18}
          >

            <Tabs
              activeKey={activeKey}
              onChange={setActiveKey}
              items={tabData.map((tabItem, i) => {
                const { name, icon: Icon, key } = tabItem;
                return {
                  label: (
                    <span>
                      <Icon />
                      {name}
                    </span>
                  ),
                  key,
                  children: renderTabContent()
                };
              })}
            />
          </Col>
          <Col
            xs={24} sm={24} md={24} lg={24} xl={6}
          >
            {renderTabAction()}
          </Col>
        </Row>

      </div>
    </BaseView>
  );
};

export default VaultDetailView;
