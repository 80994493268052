import { useCallback, useEffect, useState } from "react";
import zealyConnector from "~/services/@common/zealy.connector";
import { useAuthStore } from "~/stores/authStore"


const questsData = [
  {
    "id": "",
    "name": "Retweet for Free Mint",
    "link": "https://zealy.io/c/nftfeed/questboard/4cf236c1-b05f-4760-8e28-82021fd3737c",
  },
].map(v => ({
  ...v, id: v.link.replace("https://zealy.io/c/nftfeed/questboard/", "").trim(),
  claimed: false,
  xp: 0
}))

export const useQuestFreeMint = () => {
  const { userId } = useAuthStore();

  const [quests, setQuests] = useState(questsData)
  const loadData = useCallback(async () => {
    try {

      const getDataDetail = async (item) => {
        try {
          const result = await zealyConnector.claimedQuests({
            questId: item.id,
            userId
          }).then(({ claimed, xp }) => ({ ...item, claimed, xp }))
          return result;
        } catch (error) {
          return {
            ...item
          }
        }
      }
      const res = await Promise.all(questsData.map(v => getDataDetail(v)));
      setQuests(res);
    } catch (error) {

    }
  }, [userId]);


  useEffect(() => {
    loadData();
  }, [loadData])

  return {
    quests
  }
}
