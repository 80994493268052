



import { Avatar, Button, Col, Descriptions, Form, InputNumber, List, Row, Spin, Typography, theme } from 'antd';
import { useState } from 'react';
import { toastService } from '~/services';
import BaseView from '~/components/BaseView';
import { useSellNft } from './hooks/useSellNft';
import { ITradeNft } from './dto';


const { Paragraph } = Typography;

window.addEventListener('error', function (e) {
  console.error(e.message);
  // prevent React's listener from firing
  e.stopImmediatePropagation();
  // prevent the browser's console error message
  e.preventDefault();
});


const SellNft = (props: ITradeNft) => {
  const { token: { colorPrimary } } = theme.useToken();
  const { selectedTokenIds, setSelectedTokenIds, loadListNft } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);





  const { isAllowance, approve, sell, price, returnsGuaranteed, } = useSellNft(props);
  const onFinish = async () => {
    try {
      setIsLoading(true);
      const txLink = await sell(selectedTokenIds);
      setSelectedTokenIds([]);
      await loadListNft();
      toastService.success(txLink);
    } catch (error) {
      toastService.handleError(error);
    }
    setIsLoading(false);
  };


  return (
    <BaseView>
      <div style={{ width: '100%' }}>
        <Spin tip="Loading..." spinning={isLoading}  >
          <Row>
            <Col span={24}>
              <Descriptions column={7} title={`Swap NFT into xTOKEN`} layout="vertical" bordered size='default' >
              </Descriptions>
              <List
                grid={{
                  gutter: 24,
                  xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 3,
                  xl: 4,
                  xxl: 6,
                }}
                itemLayout="horizontal"
                dataSource={selectedTokenIds}
                renderItem={(tokenId, index) => (
                  <List.Item >
                    <List.Item.Meta
                      avatar={<Avatar size={"small"} src={`https://nftfeed.guru/static/media/logo_no_title.7966dac9.png`} />}
                      title={`#${tokenId}`}
                    //  description="TokenId"
                    />
                  </List.Item>
                )}
              />
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Descriptions title={`Price: ${Number(price).toFixed(2)} xTOKEN`} layout="vertical" bordered size='default' >
                  </Descriptions>
                </Col>
                <Col span={12}>
                  {/* <Descriptions title={`Value: ${Number(price).toFixed(2)} Feed`} layout="vertical" bordered size='default' >
                  </Descriptions> */}
                </Col>
              </Row>

            </Col>
          </Row>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item >

              <Row gutter={[24, 1]} style={{ paddingTop: 10 }}>

                {isAllowance && <>
                  <Col span={12}>
                    <Button type="primary" htmlType="submit" size="large" style={{ width: "100%" }}>
                      Swap
                    </Button>
                  </Col>

                </>

                }
                {!isAllowance && <Col span={12}>
                  <Button onClick={approve} type="primary" htmlType="button" size="large" style={{ width: "100%" }}>
                    APPROVE NFT
                  </Button>
                </Col>}
              </Row>
            </Form.Item>
          </Form>

        </Spin>

      </div>
    </BaseView>
  );
};

export default SellNft;
