


import { Button } from 'antd';
import React, { FC, useState } from 'react'
import ConnectModal from './ConnectModal';


type IConnectWalletButtonProps = {
}

const ConnectWalletButton: FC<IConnectWalletButtonProps> = (props: IConnectWalletButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div>
      <Button type="primary" onClick={() => setIsModalOpen(true)}>
        Connect Wallet
      </Button>
      <ConnectModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  )
}
export default ConnectWalletButton;
