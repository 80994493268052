import { message as messageRef, } from "antd";
import { MessageInstance, MessageType } from "antd/es/message/interface";
import { ArgsProps } from "antd/lib/message";
import { Key } from "react";
import { ApiException } from "~/@core/dto";


type ConfigContent = React.ReactNode | string;
type ConfigDuration = number | (() => void);
declare type JointContent = ConfigContent | ArgsProps;
declare type ConfigOnClose = () => void;

export class ToastService implements MessageInstance {


  private navigate;
  initNavigation(navigate) {
    this.navigate = navigate;
  }

  info(content: JointContent, duration?: ConfigDuration, onClose?: ConfigOnClose): MessageType {
    return messageRef.info(content, duration, onClose);
  }
  success(content: JointContent = "DONE", duration?: ConfigDuration, onClose?: ConfigOnClose): MessageType {

    return messageRef.success(content, duration, onClose);
  };
  error(content: JointContent, duration?: ConfigDuration, onClose?: ConfigOnClose): MessageType {
    return messageRef.error(content, duration, onClose);
  };
  warning(content: JointContent, duration?: ConfigDuration, onClose?: ConfigOnClose): MessageType {
    return messageRef.warning(content, duration, onClose);
  };
  loading(content: JointContent, duration?: ConfigDuration, onClose?: ConfigOnClose): MessageType {
    return messageRef.loading(content, duration, onClose);
  };

  open(args: ArgsProps): MessageType {
    return messageRef.open(args);
  };

  destroy(key?: Key): void {
    messageRef.destroy(key);
  }

  handleError(err: ApiException | any, type: "default" | "blockchain" = "blockchain") {
    console.log(`---------type ERRR----------`);
    console.log({ type, err });
    console.log(`-------------------`);
    if (type === "blockchain") {

      const message = (err?.reason as string ?? 'Unknown').replace("execution reverted:", "").trim(); // BASE ETH
      // const message = err?.data?.message ?? err?.message ?? (err?.reason ? err?.reason : 'Unknown'); // BNB
      this.error(message || "Unknown");
      return;
    }
    if (err.httpCode === 401) {
      this.error(err.message || "Unknown");
      this.navigate("/login");
      return;
    }
    console.log(`=====HandleError=====`, err);
    this.error(err.message || "Unknown")
  }

}

export const toastService = new ToastService();
