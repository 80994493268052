




import { List, theme } from 'antd';
import NftCard from '../InventoryV2View/NftCard';
import { useBuyNftList } from './hooks/useBuyNftList';
import { ITradeNft } from './dto';
import { useEffect } from 'react';

const pageSize = 10;

const BuyNftTab = (props: ITradeNft) => {
  const { selectedTokenIds, setSelectedTokenIds, isLoading = false, nfts, loadListNft } = props;
  const { token: { colorPrimary, colorBgBase } } = theme.useToken();
  const setTokenIds = new Set(selectedTokenIds);


  useEffect(() => { loadListNft() }, [loadListNft])

  return (
    <List
      style={{ height: '100%' }}
      loading={isLoading}
      grid={{
        gutter: 24,
        xs: 1,
        sm: 2,
        md: 3,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      pagination={{
        align: 'end',
        position: 'bottom',
        pageSize,

      }}
      dataSource={nfts}
      renderItem={(item) => (
        <List.Item onClick={() => {
          if (setTokenIds.has(item.tokenId)) {
            setTokenIds.delete(item.tokenId)

          } else {
            setTokenIds.add(item.tokenId)
          }
          setSelectedTokenIds(Array.from(setTokenIds))
        }}
          style={{ backgroundColor: setTokenIds.has(item.tokenId) ? colorPrimary : colorBgBase }}
        >
          <NftCard {...item} />
        </List.Item>
      )}
    />
  )
}
export default BuyNftTab;
